import { FirebaseError } from "@firebase/util";
import { zodResolver } from "@hookform/resolvers/zod";
import { type MouseEvent, type PropsWithChildren, useCallback, useEffect, useState } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { CgSpinner } from "react-icons/cg";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { Button } from "../components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../components/ui/form";
import { Input } from "../components/ui/input";
import { useToast } from "../components/ui/use-toast";
import {
  AuthErrorCodes,
  createUserWithEmailAndPassword,
  firebaseAuth,
  firebaseAuthProviders,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "../lib/firebase/auth";
import { classNames } from "../lib/utils";
import PageFooter from "../pages/Layout/pageFooter";
import PageHeader from "../pages/Layout/pageHeader";
import { useUser } from "./user";

const PASSWORD_LENGTH_MIN = 8;

const MESSAGES = {
  signIn: {
    header: "Welcome back!",
    withGoogle: "Sign In with Google",
    splitter: "or sign in with Email",
    button: "Sign In",
    footer: "Sign In",
    haveAnAccount: "Already have an account?",
  },
  signUp: {
    header: "Welcome to BlueArc!",
    withGoogle: "Continue with Google",
    splitter: "or sign up with Email",
    button: "Create Account",
    footer: "Sign Up",
    newUser: "New to BlueArc?",
  },
  loading: {
    global: "Loading...",
  },
  label: {
    email: "Email",
    password: "Password",
  },
  placeholder: {
    email: "Your Email",
    password: "Your Password",
  },
  forgotPassword: "Forgot Password?",
};

const authSchema = z.object({
  email: z
    .string()
    .min(1, {
      message: "Email is required",
    })
    .email("Invalid email"),
  password: z
    .string()
    .min(1, {
      message: "Password is required",
    })
    .min(PASSWORD_LENGTH_MIN, {
      message: `Password must be at least ${PASSWORD_LENGTH_MIN} characters`,
    }),
});

type AuthSchema = z.infer<typeof authSchema>;

type AuthProviderProps = PropsWithChildren<{
  requireAdmin?: boolean;
}>;

const isTemporaryHidden = true;

export function AuthProvider({ children, requireAdmin }: AuthProviderProps) {
  const [isSignInMode, setIsSignInMode] = useState(true);

  const [isSigningIn, setIsSigningIn] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isGlobalLoading, setIsGlobalLoading] = useState(true);

  const navigate = useNavigate();

  const { toast } = useToast();

  const formAuth = useForm<AuthSchema>({
    values: {
      email: "",
      password: "",
    },
    resolver: zodResolver(authSchema),
  });

  const onSubmit = useCallback<SubmitHandler<AuthSchema>>(
    async (data) => {
      try {
        await (isSignInMode ? signInWithEmailAndPassword : createUserWithEmailAndPassword)(
          firebaseAuth,
          data.email,
          data.password,
        );

        setIsAuthorized(true);

        formAuth.reset();
      } catch (error: unknown) {
        if (error instanceof FirebaseError) {
          if (error.code === AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER) {
            toast({
              variant: "destructive",
              title: "Too many attempts. Please try again later",
            });
          } else if (error.code === AuthErrorCodes.INVALID_LOGIN_CREDENTIALS) {
            toast({
              variant: "destructive",
              title: "Invalid credentials",
            });
          } else if (error.code === AuthErrorCodes.EMAIL_EXISTS) {
            toast({
              variant: "destructive",
              title: "The email is already in use",
            });
          }
        } else {
          toast({
            variant: "destructive",
            title: "Something went wrong",
          });
        }
      }
    },
    [isSignInMode, formAuth, toast],
  );

  const onSignInWithGoogle = useCallback(async () => {
    setIsSigningIn(true);

    try {
      await signInWithPopup(firebaseAuth, firebaseAuthProviders.google);

      setIsAuthorized(true);

      formAuth.reset();
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        if (error.code === AuthErrorCodes.POPUP_CLOSED_BY_USER) {
          toast({
            variant: "destructive",
            title: "Window was closed. Please try again",
          });
        }
      } else {
        toast({
          variant: "destructive",
          title: "Something went wrong",
        });
      }
    } finally {
      setIsSigningIn(false);
    }
  }, [formAuth, toast]);

  const onChangeSignInMode = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setIsSignInMode((prevState) => !prevState);
  }, []);

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      setIsAuthorized(!!user);
      setIsGlobalLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const { claims } = useUser();

  useEffect(() => {
    if (requireAdmin && !claims?.admin) {
      navigate("/404");
    }
  }, [claims?.admin, navigate, requireAdmin]);

  if (isAuthorized) {
    return <>{children}</>;
  }

  return (
    <div className="grid min-h-screen grid-rows-[auto,1fr,auto]">
      <PageHeader colorTheme="blue" />

      <main className={classNames("grid size-full px-4 py-24", isGlobalLoading ? "place-items-center" : "items-start")}>
        {isGlobalLoading ? (
          <CgSpinner className="size-10 animate-spin text-primary" aria-label={MESSAGES.loading.global} />
        ) : (
          <Form {...formAuth}>
            <form
              className="mx-auto grid w-full max-w-lg gap-8 rounded-2xl border border-theme-border bg-white px-24 py-12"
              onSubmit={formAuth.handleSubmit(onSubmit)}
            >
              <h1 className="text-center text-2xl font-bold text-theme-text-body">
                {isSignInMode ? MESSAGES.signIn.header : MESSAGES.signUp.header}
              </h1>

              <Button
                type="button"
                variant="secondary"
                disabled={isSigningIn || formAuth.formState.isSubmitting}
                className="hover:border-theme-border hover:bg-theme-background active:border-theme-border"
                onClick={onSignInWithGoogle}
              >
                <FcGoogle className="mr-3 size-5" />

                {isSignInMode ? MESSAGES.signIn.withGoogle : MESSAGES.signUp.withGoogle}
              </Button>

              <div className="flex items-center">
                <span className="grow border-b border-theme-border" />
                <span className="shrink px-5 text-sm font-medium text-theme-text-input">
                  {isSignInMode ? MESSAGES.signIn.splitter : MESSAGES.signUp.splitter}
                </span>
                <span className="grow border-b border-theme-border" />
              </div>

              <FormField
                name="email"
                control={formAuth.control}
                render={({ field }) => (
                  <FormItem className="-mb-2">
                    <FormLabel>{MESSAGES.label.email}</FormLabel>

                    <FormControl>
                      <Input type="email" placeholder={MESSAGES.placeholder.email} {...field} />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                name="password"
                control={formAuth.control}
                render={({ field }) => (
                  <FormItem className="-mb-2">
                    <FormLabel>
                      {MESSAGES.label.password}

                      {!isTemporaryHidden && isSignInMode && (
                        <button className="ml-auto bg-transparent p-0 text-xs font-medium text-primary">
                          {MESSAGES.forgotPassword}
                        </button>
                      )}
                    </FormLabel>

                    <FormControl>
                      <Input type="password" placeholder={MESSAGES.placeholder.password} {...field} />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />

              {!isSignInMode && (
                <p className="-mb-2 text-sm text-theme-text-body">
                  By proceeding, you agree to our{" "}
                  <a href="/terms-of-use" className="text-sm text-primary" target="_blank" rel="noopener noreferrer">
                    Terms of Use
                  </a>{" "}
                  and confirm you have read our{" "}
                  <a href="/privacy-policy" className="text-sm text-primary" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                  </a>
                  .
                </p>
              )}

              <Button
                type="submit"
                disabled={isSigningIn || formAuth.formState.isSubmitting || !formAuth.formState.isValid}
              >
                {isSignInMode ? MESSAGES.signIn.button : MESSAGES.signUp.button}

                {(isSigningIn || formAuth.formState.isSubmitting) && <CgSpinner className="ml-3 size-5 animate-spin" />}
              </Button>

              <div className="flex justify-center gap-2 text-base text-theme-text-body">
                {isSignInMode ? MESSAGES.signUp.newUser : MESSAGES.signIn.haveAnAccount}

                <button className="bg-transparent p-0 text-base font-bold text-primary" onClick={onChangeSignInMode}>
                  {isSignInMode ? MESSAGES.signUp.footer : MESSAGES.signIn.footer}
                </button>
              </div>
            </form>
          </Form>
        )}
      </main>

      <PageFooter displayContact={false} />
    </div>
  );
}
