import { FC, type MouseEventHandler } from "react";

import { RISKS } from "../../../../constants/risk-summary";
import { classNames, ensureHttps } from "../../../../lib/utils";
import InsertedHtml from "../../../extra/inserted-html";
import { ShowMoreButton } from "../../../extra/show-more-button";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../../ui/accordion";
import { getRiskScoreStatus } from "../../../ui/risk-score";
import DetailRow from "../../detail-row";

export type ScoreCategoriesV3Props = {
  tooltipId: string;
  onCopyLink: (params: { id: string; value: string }) => MouseEventHandler<HTMLButtonElement>;
  copiedText: string;
  // TODO: domanskyi - fix typing!!!!!!!!
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  groupedDetails: any;
  isGroupedDetailsExpanded: (title: string) => boolean;
  onToggleExpandedDetailsBlock: (title: string) => void;
};

const parseObjectValue = (value: unknown): string => {
  if (typeof value === "object") {
    // @ts-ignore
    if (!!value.visible) {
      // @ts-ignore
      return value.visible;
    }

    // @ts-ignore
    if (!!value.rawLocationString) {
      // @ts-ignore
      return value.rawLocationString;
    }

    // @ts-ignore
    return Object.values(value).join(", ");
  }

  return value as string;
};

export const ScoreCategoriesV3: FC<ScoreCategoriesV3Props> = ({
  groupedDetails,
  isGroupedDetailsExpanded,
  onToggleExpandedDetailsBlock,
}) => {
  return (
    <div className="grid gap-8">
      {// @ts-ignore
      groupedDetails?.section_attributes.map((section_attribute) => {
        const sectionAttributeValues = !!section_attribute.num_values
          ? section_attribute.num_values
          : section_attribute?.values?.length;

        return (
          <div
            className={classNames(
              "relative grid gap-3 border-b pb-8 pl-64 last:border-none",
              !!section_attribute?.values?.length ? "last:pb-0" : "",
            )}
          >
            <h4 className="absolute left-0 my-1.5 max-w-60 text-base font-bold text-theme-text-body">
              {section_attribute.attribute_name} ({sectionAttributeValues})
            </h4>
            <div
              style={{
                display: "flex",
                flexFlow: "column",
              }}
            >
              {(isGroupedDetailsExpanded(section_attribute.attribute_name)
                ? section_attribute.values
                : section_attribute.values.slice(0, 5)
              ) // @ts-ignore
                .sort((a, b) => {
                  return b.sources.length - a.sources.length;
                }) // @ts-ignore
                .map((value) => {
                  const sources = value.sources;
                  const visibleValue = value.html_value ? value.html_value : parseObjectValue(value.value);
                  const visibleValueUrl = value?.value?.url ? value?.value?.url : value?.url;
                  const valueScore = value.score;

                  const detailStatus = !!valueScore ? getRiskScoreStatus(valueScore) : null;

                  if (sources.length === 1) {
                    return (
                      <div
                        className="text-sm font-medium"
                        style={{
                          paddingTop: "12px",
                          paddingBottom: "12px",
                        }}
                      >
                        {
                          // @ts-ignore
                          sources.map((source) => (
                            <DetailRow
                              detail={{
                                source_url: ensureHttps(source.url),
                                visible_value: parseObjectValue(source.value),
                                value: parseObjectValue(source.visible),
                                score: source.score,
                                html_value: source.html_value,
                              }}
                            />
                          ))
                        }
                      </div>
                    );
                  }

                  return (
                    <div>
                      <Accordion type="single" collapsible>
                        <AccordionItem value={parseObjectValue(value.value)}>
                          <AccordionTrigger chevronPosition="start" className="hover:no-underline">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                paddingLeft: "0.25rem",
                                gap: "1rem",
                              }}
                            >
                              {visibleValueUrl ? (
                                <a
                                  href={visibleValueUrl}
                                  className="text-primary underline"
                                  onClick={(e) => e.stopPropagation()}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {visibleValue}
                                </a>
                              ) : (
                                <div style={{ textAlign: "left" }}>
                                  <InsertedHtml value={visibleValue} />
                                </div>
                              )}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "0.5rem",
                                }}
                              >
                                <span
                                  style={{
                                    textWrap: "nowrap",
                                  }}
                                >{`${sources.length} ${sources.length === 1 ? "source" : "sources"}`}</span>
                                {detailStatus && !!RISKS[detailStatus] && (
                                  <p
                                    className={classNames(
                                      "flex h-6 w-20 items-center justify-center rounded-s-full font-bold text-white",
                                      RISKS[detailStatus].backgroundColor,
                                    )}
                                    style={{
                                      maxWidth: "3rem",
                                    }}
                                  >
                                    {valueScore}
                                  </p>
                                )}
                              </div>
                            </div>
                          </AccordionTrigger>
                          <AccordionContent>
                            <div
                              style={{
                                paddingLeft: "20px",
                              }}
                            >
                              {
                                // @ts-ignore
                                sources.map((source) => (
                                  <DetailRow
                                    detail={{
                                      source_url: ensureHttps(source.url),
                                      visible_value: parseObjectValue(source.value),
                                      value: parseObjectValue(source.visible),
                                      score: source.score,
                                      html_value: source.html_value,
                                    }}
                                  />
                                ))
                              }
                            </div>
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    </div>
                  );
                })}
            </div>
            {sectionAttributeValues > 5 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                <ShowMoreButton
                  onClick={() => onToggleExpandedDetailsBlock(section_attribute.attribute_name)}
                  isShowingMore={isGroupedDetailsExpanded(section_attribute.attribute_name)}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
