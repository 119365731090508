import { FC } from "react";

import { sanitizeHTML } from "../../lib/utils";

type InsertedHtmlProps = {
  value: string;
};

const InsertedHtml: FC<InsertedHtmlProps> = ({ value }) => {
  return (
    <div>
      <div className="inserted-html" dangerouslySetInnerHTML={{ __html: sanitizeHTML(value) }} />
    </div>
  );
};

export default InsertedHtml;
