import { Button } from "../../ui/button";

const DigIn = () => {
  return (
    <Button
      size="sm"
      style={{
        height: "1.5rem",
      }}
      onClick={() => window.alert("Dig In")}
    >
      Dig In
    </Button>
  );
};

export default DigIn;
