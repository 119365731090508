import ApiConfig from "./ApiConfig";
import HttpService from "./HttpService";
// import mockResponse from "./mock-res.json";
import type {
  BulkUploadParams,
  BusinessScoresParams,
  BusinessScoresResponse,
  ProcessStepsParams,
  SignUploadUrlParams,
  SignUploadUrlResponse,
  UserBatchesHistoryResponse,
} from "./Types";

export const verifyToken = async (payload?: any) => {
  let apiUrl = `${ApiConfig.verifyToken}`;
  const queryParam = payload?.queryParam || "";

  if (queryParam) {
    apiUrl = `${ApiConfig.verifyToken}?${queryParam}`;
  }

  return await HttpService.get(apiUrl);
};

export const registerVoucher = async (payload?: any) => {
  let apiUrl = `${ApiConfig.registerVoucher}`;
  const queryParam = payload?.queryParam || "";

  if (queryParam) {
    apiUrl = `${ApiConfig.verifyToken}?${queryParam}`;
  }

  return await HttpService.post(apiUrl);
};

export async function readBusinessScores<R = BusinessScoresResponse>(params: BusinessScoresParams) {
  const { data } = await HttpService.get<R>(ApiConfig.businessScores, {
    params,
  });

  // return mockResponse as unknown as BusinessScoresResponse;

  return data;
}

export async function readProcessStep(params: ProcessStepsParams) {
  const { data } = await HttpService.get(ApiConfig.processSteps, {
    params,
  });

  return data;
}

export async function readUserBatchesHistory() {
  const { data } = await HttpService.get<UserBatchesHistoryResponse>(ApiConfig.userBatchesHistory);

  return {
    ...data,
    batches: [...data.batches].sort((a, b) => (a.request_timestamp < b.request_timestamp ? 1 : -1)),
  };
}

export async function readSignUploadUrl(params: SignUploadUrlParams) {
  const { data } = await HttpService.get<SignUploadUrlResponse>(ApiConfig.signUploadUrl, {
    params,
  });

  return data;
}

export async function createBulkUpload(params: BulkUploadParams) {
  return HttpService.put(params.url, params.file, {
    headers: {
      "Content-Type": "application/octet-stream",
    },
  });
}
