import Bad from "./bad";
import DigIn from "./dig-in";
import Good from "./good";

const Feedback = () => {
  return (
    <div className="flex gap-1">
      <Good />
      <Bad />
      <DigIn />
    </div>
  );
};

export default Feedback;
