import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { GoChevronLeft } from "react-icons/go";
import { useNavigate, useSearchParams } from "react-router-dom";

import ProcessSteps from "../../components/process-steps";
import ProcessStepsForm from "../../components/process-steps-form";
import { toast } from "../../components/ui/use-toast";
import { classNames } from "../../lib/utils";
import { readProcessStep } from "../../Services/Services";
import PageFooter from "../Layout/pageFooter";
import PageHeader from "../Layout/pageHeader";
import stepsData from "./steps.json";
// mock data for test
const processSteps = stepsData;

const BackButton: React.FC<{ label?: string }> = ({ label = "Go Back" }) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Goes back to the previous page
  };

  return (
    <button
      onClick={handleGoBack}
      className="flex items-center px-4 py-2 text-sm font-medium text-primary hover:text-secondary"
    >
      <GoChevronLeft className="mr-2" />
      {label}
    </button>
  );
};

export function ProcessStepsPage() {
  const [searchParams] = useSearchParams();

  const processId = searchParams.get("processId");
  const url = searchParams.get("url");

  const processStepsByUrl = useQuery({
    queryKey: ["processSteps", { url }],
    queryFn: async () => processSteps,
  });

  const processStepsById = useQuery({
    queryKey: ["processStep", { processId }],
    queryFn: async () => {
      if (!!processId) {
        toast({
          variant: "default",
          title: "Fetching process steps",
        });
      }

      const params = { process_id: processId as string };

      try {
        const response = await readProcessStep(params);

        return response;
      } catch (error) {
        toast({
          variant: "destructive",
          title: "Error fetching process steps",
        });

        return null;
      }
    },
    enabled: !!processId,
  });

  const isLoading = useMemo(
    () => processStepsByUrl.isFetching || processStepsById.isFetching,
    [processStepsById.isFetching, processStepsByUrl.isFetching],
  );

  const stepsData = useMemo(() => {
    if (!!processId) {
      return processStepsById.data;
    }

    return processStepsByUrl.data;
  }, [processId, processStepsById.data, processStepsByUrl.data]);

  return (
    <div
      className={classNames(
        "grid min-h-screen",
        processSteps ? "grid-rows-[auto,auto,1fr,auto]" : "grid-rows-[auto,1fr,auto]",
      )}
    >
      <PageHeader colorTheme="blue" />

      <main className={classNames("size-full px-4 pb-14", "pt-14")}>
        <div className="mx-auto grid w-full max-w-6xl gap-10">
          <div className="grid gap-5 align-baseline md:grid-cols-1 md:gap-y-10">
            <h1 className="text-3xl font-bold text-theme-text-body md:my-0.5">Process steps</h1>
            <BackButton />
            <ProcessStepsForm isLoading={isLoading} />
            <ProcessSteps data={stepsData} isLoading={isLoading} />
          </div>
        </div>
      </main>

      <PageFooter displayContact={false} />
    </div>
  );
}
export default ProcessStepsPage;
