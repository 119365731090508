import { z } from "zod";

import { ACCEPTED_FILE_TYPES, MAX_FILE_SIZE_IN_MB } from "../file";

function getFileSizeInMB(fileSizeInBytes?: number) {
  return +(Number(fileSizeInBytes) / (1024 * 1024)).toFixed(2);
}

export const urlSchema = z.object({
  url: z.string().min(1, ""),
});

export const riskSummaryFormSchema = z
  .object({
    url: z.string().optional(),
    file: z
      .instanceof(FileList)
      .refine(
        (fileList) => !fileList.length || ACCEPTED_FILE_TYPES.includes(fileList?.item(0)?.type || ""),
        "Invalid CSV file",
      )
      .refine(
        (fileList) => !fileList.length || getFileSizeInMB(fileList?.item(0)?.size) <= MAX_FILE_SIZE_IN_MB,
        `Max file size allowed is ${MAX_FILE_SIZE_IN_MB}MB`,
      )
      .optional(),
  })
  .refine(({ url, file }) => !!url || !!file?.length, {
    message: "Client URL is required",
    path: ["url"],
  });

export type UrlSchema = z.infer<typeof urlSchema>;

export type RiskSummaryFormSchema = z.infer<typeof riskSummaryFormSchema>;
