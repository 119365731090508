import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { GoArrowRight } from "react-icons/go";

import { UrlSchema, urlSchema } from "../../../constants/validations/risk-summary";
import { Button } from "../../ui/button";
import { Form, FormControl, FormField, FormItem, FormMessage } from "../../ui/form";
import { Input } from "../../ui/input";

export function DigInForm() {
  const formUrl = useForm<UrlSchema>({
    resolver: zodResolver(urlSchema),
    defaultValues: {
      url: "",
    },
  });

  return (
    <Form {...formUrl}>
      <form className="flex w-full flex-row justify-end gap-2" onSubmit={formUrl.handleSubmit((_) => {})}>
        <FormField
          name="url"
          control={formUrl.control}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input placeholder="" {...field} />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />

        <Button type="submit">
          Dig In
          <GoArrowRight className="ml-3 size-5" />
        </Button>
      </form>
    </Form>
  );
}
export default DigInForm;
