import { FC } from "react";

import { RISKS } from "../../constants/risk-summary";
import useLayoutVersion from "../../hooks/useLayoutVersion";
import { classNames, isUrl } from "../../lib/utils";
import { getHostnameFromURL } from "../../lib/utils";
import { TDetailsEntity } from "../../Utils/risk-summary";
import InsertedHtml from "../extra/inserted-html";
import Feedback from "../feedback";
import { getRiskScoreStatus } from "../ui/risk-score";

type TDetailRowProps = {
  detail: TDetailsEntity;
};

const DetailRowValueV2: FC<TDetailRowProps> = ({ detail }) => {
  const visibleValue = detail.html_value || detail.visible_value;

  return (
    <>
      {detail?.visible_value?.startsWith("http") ? (
        <a href={detail.visible_value} className="text-primary underline" target="_blank" rel="noopener noreferrer">
          <p
            className="my-1.5 "
            style={{
              maxWidth: "70%",
            }}
          >
            {getHostnameFromURL(visibleValue, 150)}
          </p>
        </a>
      ) : (
        <>
          {detail.html_value ? (
            <div
              className="my-1.5 "
              style={{
                maxWidth: "65%",
              }}
            >
              <InsertedHtml value={visibleValue} />
            </div>
          ) : (
            <p
              className="my-1.5 "
              style={{
                maxWidth: "65%",
              }}
            >
              {getHostnameFromURL(visibleValue, 150)}
            </p>
          )}
        </>
      )}
    </>
  );
};

const DetailRow: FC<TDetailRowProps> = ({ detail }) => {
  const score = detail?.score ? +detail.score : undefined;

  const detailStatus = typeof score === "number" && getRiskScoreStatus(score);

  const { version } = useLayoutVersion();

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0.25rem 0rem",
          maxWidth: "calc(72rem - 256px - 2rem - 2rem)",
          width: "100%",
          gap: "2rem",
        }}
      >
        {version === 1 ? (
          <>
            {detail?.visible_value?.startsWith("http") ? (
              <a
                href={detail.visible_value}
                className="text-primary underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="my-1.5 w-full truncate">{getHostnameFromURL(detail.visible_value)}</p>
              </a>
            ) : (
              <p className="my-1.5 w-full truncate">{getHostnameFromURL(detail.visible_value)}</p>
            )}
          </>
        ) : (
          <DetailRowValueV2 detail={detail} />
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: ".5rem",
            alignItems: "center",
          }}
        >
          {isUrl(detail.source_url) ? (
            <a
              href={detail.source_url}
              className="my-1.5 truncate text-primary underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              {detail.value ? getHostnameFromURL(detail.value) : getHostnameFromURL(detail.source_url)}
            </a>
          ) : (
            <p
              style={{
                textAlign: "right",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {detail.value ? getHostnameFromURL(detail.value) : getHostnameFromURL(detail.source_url)}
            </p>
          )}
          {detailStatus && !!RISKS[detailStatus] && (
            <p
              className={classNames(
                "flex h-6 w-20 items-center justify-center rounded-s-full font-bold text-white",
                RISKS[detailStatus].backgroundColor,
              )}
              style={{
                maxWidth: "3rem",
              }}
            >
              {detail.score}
            </p>
          )}
          {version === 3 && <Feedback />}
        </div>
      </div>
      <div
        style={{
          maxWidth: "65%",
        }}
        className="text-sm text-gray-500"
      >
        <p>{detail.snippet}</p>
      </div>
    </div>
  );
};

export default DetailRow;
