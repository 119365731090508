import { FC } from "react";

import type { BusinessScoresResponse } from "../../../Services/Types";
import { RiskScore } from "../../ui/risk-score";

export type TotalRiskScoreProps = {
  dataBusinessScores: BusinessScoresResponse;
};

export const TotalRiskScore: FC<TotalRiskScoreProps> = ({ dataBusinessScores }) => (
  <div className="grid w-fit content-start gap-5 rounded-2xl border bg-white p-8">
    <h2 className="mb-2 text-2xl font-bold text-theme-text-body">Total Risk Score</h2>

    <div className="flex flex-col items-center gap-5 lg:flex-row">
      <RiskScore
        size="lg"
        score={dataBusinessScores.v2?.total_risk_score?.overall_score ?? dataBusinessScores.overall_score}
      />
    </div>
  </div>
);
