import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import { Button } from "../../components/ui/button";
import { Form, FormControl, FormField, FormItem, FormMessage } from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { UrlSchema, urlSchema } from "../../constants/validations/risk-summary";
import useRunUrlProcessingCache from "../../hooks/useRunUrlProcessingCache";
import { classNames } from "../../lib/utils";
import { readBusinessScores } from "../../Services/Services";
import { BusinessScoresV3Response } from "../../Services/Types";
import PageFooter from "../Layout/pageFooter";
import PageHeader from "../Layout/pageHeader";

export function RunUrlProcessing() {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryUrl = searchParams.get("url");
  const queryProcessStepId = searchParams.get("processStepId");

  const formUrl = useForm<UrlSchema>({
    resolver: zodResolver(urlSchema),
    defaultValues: {
      url: queryUrl || "",
    },
  });

  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["runUrlProcessing"],
    mutationFn: async (values: { url: string }) => {
      const response = await readBusinessScores<BusinessScoresV3Response>({
        url: values.url,
        api_version: "3",
      });

      // @ts-ignore
      setUrlCache(values.url, response);
      // @ts-ignore
      setSearchParams({ url: values.url, processStepId: response.process_step_id });

      return response;
    },
  });

  const urlValue = formUrl.watch("url");

  const { setUrlCache, getUrlCache } = useRunUrlProcessingCache();

  return (
    <div className={classNames("grid min-h-screen")}>
      <PageHeader colorTheme="blue" />

      <main className={classNames("size-full px-4 pb-14", "pt-14")}>
        <div className="mx-auto grid w-full max-w-6xl gap-10">
          <div className="grid gap-5 align-baseline md:grid-cols-1 md:gap-y-10">
            <Form {...formUrl}>
              <form
                className="mx-auto grid w-full max-w-2xl gap-6 rounded-2xl border border-theme-border bg-white px-24 py-12"
                onSubmit={formUrl.handleSubmit((data) => {
                  const cache = getUrlCache(data.url);

                  if (cache) {
                    setSearchParams({ url: data.url, processStepId: cache.process_step_id });
                    return;
                  }

                  mutateAsync(data);
                })}
              >
                <FormField
                  name="url"
                  control={formUrl.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input label="Url" {...field} />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />

                <Button type="submit" disabled={isPending || urlValue === queryUrl}>
                  Submit
                </Button>
                {queryUrl && (
                  <div className="text-center">
                    <a className="text-primary underline" href={`client?url=${queryUrl}&v=3`}>
                      View Risk Summary of {queryUrl}
                    </a>
                  </div>
                )}
                {queryProcessStepId && (
                  <div className="text-center">
                    <a
                      className="text-primary underline"
                      href={`process-steps?url=${queryUrl}&processId=${queryProcessStepId}`}
                    >
                      View Process Steps
                    </a>
                  </div>
                )}
              </form>
            </Form>
          </div>
        </div>
      </main>

      <PageFooter displayContact={false} />
    </div>
  );
}
export default RunUrlProcessing;
