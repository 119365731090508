import { FC } from "react";
import { type MouseEventHandler } from "react";

import { compactJoin } from "../../../lib/utils";
import type { ScoreCategoryKey } from "../../../Services/Types";
import { RiskScore } from "../../ui/risk-score";

export type ScoreCategoriesTickersProps = {
  tickers: Array<{
    name: string;
    score: string;
  }>;
  onScrollToSection: (section: ScoreCategoryKey) => MouseEventHandler<HTMLButtonElement>;
};

export const ScoreCategoriesTickers: FC<ScoreCategoriesTickersProps> = ({ tickers, onScrollToSection }) => {
  return (
    <div className="grid grid-cols-2 gap-3 md:col-span-2 md:grid-cols-3 lg:grid-cols-5">
      {tickers?.map(({ name, score }) => {
        return (
          <div
            key={compactJoin(["detailed", "score", "block", name], "_")}
            className="grid content-between justify-items-center gap-5 rounded-xl border bg-white p-5"
          >
            <header className="flex w-full justify-between gap-2">
              <h3 className="text-base font-bold text-theme-text-body">{name}</h3>
            </header>

            <RiskScore
              size="md"
              // @ts-ignore
              score={score}
            />
          </div>
        );
      })}
    </div>
  );
};
