import { BiDownvote } from "react-icons/bi";

import { Button } from "../../ui/button";

const Bad = () => {
  return (
    <Button
      onClick={() => window.alert("Bad")}
      size="icon"
      className="bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent"
    >
      <BiDownvote className=" text-primary" />
    </Button>
  );
};

export default Bad;
