import { getAuth, onAuthStateChanged, ParsedToken, User } from "firebase/auth";
import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from "react";

import LoadingScreen from "../components/loading-screen";

type ProviderState = {
  user: User | null;
  loading: boolean;
  claims: ParsedToken | null;
};

const UserContext = createContext<ProviderState>({
  user: null,
  loading: true,
  claims: null,
});

type UserProviderProps = PropsWithChildren;

export const UserProvider: FC<UserProviderProps> = ({ children }: UserProviderProps) => {
  const [userState, setUserState] = useState<ProviderState>({
    user: null,
    claims: null,
    loading: true,
  });

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const idTokenResult = await user.getIdTokenResult();

          setUserState({
            user,
            claims: idTokenResult.claims,
            loading: false,
          });
        } catch (error) {
          console.error("Error fetching user claims:", error);
          setUserState({ user: null, claims: null, loading: false });
        }
      } else {
        setUserState({ user: null, claims: null, loading: false });
      }
    });

    return () => unsubscribe();
  }, []);

  if (userState.loading) {
    return <LoadingScreen />;
  }

  return <UserContext.Provider value={userState}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  return useContext(UserContext);
};
