import { CgSpinner } from "react-icons/cg";

const LoadingScreen = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#ececfa",
        zIndex: 9999,
        width: "100%",
        height: "100%",
      }}
    >
      <CgSpinner className="size-10 animate-spin text-primary" />
    </div>
  );
};

export default LoadingScreen;
