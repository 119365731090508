export const RISKS = {
  good: {
    title: "Well-Established",
    description: "This business is credible",
    textColor: "text-status-low",
    backgroundColor: "bg-status-low",
    beforeBackgroundColor: "before:bg-status-low",
  },
  unknown: {
    title: "Not Well-Established",
    description: "There is not enough footprint to know this business is good, and it could be fraud",
    textColor: "text-status-neutral",
    backgroundColor: "bg-status-neutral",
    beforeBackgroundColor: "before:bg-status-neutral",
  },
  risky: {
    title: "Known Risk",
    description: "There is evidence indicating high risk for this business",
    textColor: "text-status-high",
    backgroundColor: "bg-status-high",
    beforeBackgroundColor: "before:bg-status-high",
  },
};

export const SCORE_CATEGORIES: Record<
  string,
  {
    title: string;
  }
> = {
  // new
  business_overview: {
    title: "Business Overview",
  },
  business_size: {
    title: "Business Size",
  },
  locations_and_contact: {
    title: "Locations & Contact",
  },
  related_business_entities: {
    title: "Related Business Entities",
  },
  products_and_services: {
    title: "Products & Services",
  },
  reliability_and_risks: {
    title: "Reliability & Risks",
  },
  online_presence: {
    title: "Online Presence",
  },
  people: {
    title: "People",
  },
  // uppercase

  BUSINESS_OVERVIEW: {
    title: "Business Overview",
  },
  BUSINESS_SIZE: {
    title: "Business Size",
  },
  LOCATIONS_AND_CONTACT: {
    title: "Locations & Contact",
  },
  RELATED_BUSINESS_ENTITIES: {
    title: "Related Business Entities",
  },
  PRODUCTS_AND_SERVICES: {
    title: "Products & Services",
  },
  RELIABILITY_AND_RISKS: {
    title: "Reliability & Risks",
  },
  ONLINE_PRESENCE: {
    title: "Online Presence",
  },
  PEOPLE: {
    title: "People",
  },

  //
  BUSINESS_RELATIONSHIPS: {
    title: "Business Relationships",
  },
  COMPOSITE_BUSINESS_RISKS: {
    title: "Composite Business Risk",
  },
  PRODUCTS_SERVICES: {
    title: "Products & Services",
  },
  BUSINESS_PRESENCE: {
    title: "Business Presence",
  },
  // ONLINE_PRESENCE: {
  //   title: "Online Presence",
  // },
  HISTORICAL_PRESENCE: {
    title: "Historical Presence",
  },
  LEADERSHIP_PRESENCE: {
    title: "Leadership Presence",
  },
  ASSOCIATED_BUSINESSES: {
    title: "Associated Businesses",
  },
};
