import { FC, MouseEventHandler, useMemo } from "react";

import { SCORE_CATEGORIES } from "../../../constants/risk-summary";
import useLayoutVersion from "../../../hooks/useLayoutVersion";
import { classNames, compactJoin, formatSectionName } from "../../../lib/utils";
import type { ScoreCategoryKey } from "../../../Services/Types";
import type { BusinessScoresResponse } from "../../../Services/Types";

export type NavigationProps = {
  activeSection: ScoreCategoryKey | null;
  onScrollToSection: (section: ScoreCategoryKey | null) => () => MouseEventHandler<HTMLButtonElement>;
  dataBusinessScores: BusinessScoresResponse;
};

export const Navigation: FC<NavigationProps> = ({ activeSection, onScrollToSection, dataBusinessScores }) => {
  const { version } = useLayoutVersion();

  const sections: Array<[string, { title: string }]> = useMemo(() => {
    if (version !== 2) {
      return Object.entries(SCORE_CATEGORIES);
    }

    // @ts-ignore
    return dataBusinessScores?.map((section) => [
      section?.section_name,
      {
        title: formatSectionName(section?.section_name),
      },
    ]);
  }, [dataBusinessScores, version]);

  return (
    <section
      className={classNames(
        "invisible sticky top-16 z-20 grid h-14 overflow-x-auto border-y border-theme-border bg-white px-4 shadow-lg shadow-black/10",
        activeSection && "visible",
      )}
    >
      <div className="mx-auto flex w-full max-w-6xl">
        <ul className="flex items-end gap-8">
          <li>
            <button
              className="border-b-2 border-transparent bg-transparent p-0 pb-3.5 text-base font-bold text-theme-text-input"
              onClick={onScrollToSection(null)}
            >
              Home
            </button>
          </li>

          {sections?.map(([key, category]) => {
            const categoryKey = key as ScoreCategoryKey;
            const sectionDetails = dataBusinessScores.v2?.detailed_scores?.find(
              (detailedScore) => detailedScore.section_title === categoryKey,
            );

            const isSectionLeadershipPresence = sectionDetails?.section_title === "LEADERSHIP_PRESENCE";
            // @ts-ignore
            const details =
              version === 2 || version === 3
                ? // @ts-ignore
                  dataBusinessScores.find((section) => section.section_name === categoryKey)?.section_attributes
                : isSectionLeadershipPresence
                  ? // @ts-ignore
                    sectionDetails?.person_details
                  : sectionDetails?.details;

            if (!details?.length) {
              return null;
            }

            return (
              <li key={compactJoin(["scroll", "navigation", key], "_")}>
                <button
                  className={classNames(
                    "whitespace-nowrap border-b-2 bg-transparent p-0 pb-3.5 text-base font-bold text-theme-text-input",
                    activeSection === categoryKey ? "border-current text-primary" : "border-transparent",
                  )}
                  onClick={onScrollToSection(categoryKey)}
                >
                  {category.title}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};
