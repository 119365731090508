import { classNames } from "../../../lib/utils";
import type { IconProps } from "../icon";

type RiskScoreProps = {
  size: "lg" | "md" | "sm";
  score: number;
  className?: string;
};

type RiskScoreImageProps = IconProps & {
  status?: "good" | "unknown" | "risky";
  angle?: number;
};

export function getRiskScoreAngle(score: RiskScoreProps["score"]): NonNullable<RiskScoreImageProps["angle"]> {
  const angleDefault = -90;
  const angleMax = 30;
  const angleMin = -210;

  if (score > 0 && score <= 10) {
    return angleDefault + (score / 10) * (angleMax - angleDefault);
  } else if (score < 0 && score >= -10) {
    return angleDefault + (score / 10) * (angleDefault - angleMin);
  } else {
    return angleDefault;
  }
}

export function getRiskScoreStatus(score: RiskScoreProps["score"]): NonNullable<RiskScoreImageProps["status"]> {
  // 3..10     - good
  if (score >= 3) {
    return "good";
  }
  // 0..2.9    - unknown
  else if (score >= 0) {
    return "unknown";
  }
  // -10..-0.1 - risky
  else {
    return "risky";
  }
}

function RiskScoreImageLarge({ angle, ...props }: RiskScoreImageProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="210" height="210" fill="none" {...props}>
      <path
        fill="#f8f8f8"
        d="M173.628 105c0 37.902-30.726 68.627-68.628 68.627S36.373 142.902 36.373 105c0-37.902 30.725-68.627 68.627-68.627 37.902 0 68.628 30.725 68.628 68.627Z"
      />

      <path
        fill="url(#a)"
        d="M210 105C210 47.01 162.99 0 105 0S0 47.01 0 105c0 20.8 6.048 40.188 16.482 56.5l35.436-13c-9.715-11.84-15.545-26.989-15.545-43.5 0-37.902 30.725-68.627 68.627-68.627 37.902 0 68.627 30.725 68.627 68.627 0 16.511-5.83 31.66-15.544 43.5l31.665 11.617c2.202.808 4.681-.018 5.869-2.041C204.759 142.503 210 124.363 210 105Z"
      />

      <path
        fill="url(#b)"
        d="M202 105c0-53.572-43.428-97-97-97S8 51.428 8 105c0 12.203 2.254 23.881 6.367 34.638 1.021 2.669-.89 5.633-3.748 5.633-1.586 0-3.022-.952-3.594-2.431C2.488 131.1 0 118.341 0 105 0 47.01 47.01 0 105 0s105 47.01 105 105c0 13.341-2.488 26.1-7.025 37.84-.572 1.479-2.008 2.431-3.594 2.431-2.859 0-4.769-2.964-3.748-5.633C199.746 128.881 202 117.203 202 105Z"
      />

      <path
        fill="#000"
        d="m142.715 133.713 46.46-28.082a1 1 0 0 0 0-1.712l-46.46-28.082V95.56h-37.94v18.156h37.94v19.998Z"
        style={{
          transform: `rotate(${angle}deg)`,
          transformOrigin: "105px 105px",
        }}
      />

      <g filter="url(#c)">
        <circle cx="105" cy="105" r="68.627" fill="#fff" />
      </g>

      <path
        fill="#fff"
        stroke="#e4e3ea"
        d="M161.5 105c0 31.204-25.296 56.5-56.5 56.5S48.5 136.204 48.5 105 73.796 48.5 105 48.5s56.5 25.296 56.5 56.5Z"
      />

      <path
        fill="url(#d)"
        d="M202 105c0-53.572-43.428-97-97-97S8 51.428 8 105c0 13.883 2.917 27.085 8.17 39.027 1.023 2.323-.227 5.061-2.688 5.682-1.827.46-3.733-.425-4.497-2.146C3.209 134.553 0 120.151 0 105 0 47.01 47.01 0 105 0s105 47.01 105 105c0 15.151-3.209 29.553-8.985 42.562-.764 1.722-2.67 2.607-4.497 2.147-2.461-.621-3.711-3.359-2.689-5.682C199.083 132.085 202 118.883 202 105Z"
      />

      <defs>
        <linearGradient id="a" x1="19" x2="197" y1="161" y2="161" gradientUnits="userSpaceOnUse">
          <stop stopColor="#cdcdec" />
          <stop offset="1" stopColor="#f6f6ff" />
        </linearGradient>

        <linearGradient id="b" x1="201" x2="5.5" y1="145" y2="145" gradientUnits="userSpaceOnUse">
          <stop stopColor="#018001" />
          <stop offset=".135" stopColor="#eca336" />
          <stop offset=".805" stopColor="#eca336" />
          <stop offset="1" stopColor="#f00" />
        </linearGradient>

        <linearGradient id="d" x1="16.5" x2="195" y1="162" y2="162" gradientUnits="userSpaceOnUse">
          <stop stopColor="#ff0903" />
          <stop offset=".535" stopColor="#eda337" />
          <stop offset=".695" stopColor="#eda337" />
          <stop offset=".81" stopColor="#eda337" />
          <stop offset="1" stopColor="#018001" />
        </linearGradient>

        <filter
          id="c"
          width="167.255"
          height="167.255"
          x="21.373"
          y="31.373"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.92549 0 0 0 0 0.92549 0 0 0 0 0.980392 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_746_64324" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_746_64324" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

function RiskScoreImageMedium({ status, angle, ...props }: RiskScoreImageProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="114" height="114" fill="none" {...props}>
      <path
        fill="#cdcdec"
        d="M109.657 57c0-29.082-23.575-52.657-52.657-52.657C27.918 4.343 4.343 27.918 4.343 57a52.534 52.534 0 0 0 3.456 18.803c.555 1.45-.483 3.059-2.034 3.059-.861 0-1.641-.517-1.951-1.32A56.86 56.86 0 0 1 0 57C0 25.52 25.52 0 57 0s57 25.52 57 57a56.863 56.863 0 0 1-3.814 20.542 2.082 2.082 0 0 1-1.951 1.32c-1.551 0-2.589-1.61-2.034-3.059A52.537 52.537 0 0 0 109.657 57Z"
      />

      {status === "risky" && (
        <path
          fill="#f00"
          d="M57 4.343C27.918 4.343 4.343 27.918 4.343 57a52.534 52.534 0 0 0 3.456 18.803c.555 1.45-.483 3.059-2.034 3.059-.861 0-1.641-.517-1.951-1.32A56.86 56.86 0 0 1 0 57C0 25.52 25.52 0 57 0v4.343Z"
        />
      )}

      {status === "unknown" && (
        <path
          fill="#eca336"
          d="M57 4.343V0c11.431 0 22.077 3.365 31 9.159l-3 3.237c-8.11-5.102-17.71-8.053-28-8.053Z"
        />
      )}

      {status === "good" && (
        <path
          fill="#018001"
          d="M106.201 75.803A52.537 52.537 0 0 0 109.657 57c0-18.792-9.844-35.285-24.657-44.604l3-3.237C103.649 19.32 114 36.95 114 57a56.863 56.863 0 0 1-3.814 20.542 2.082 2.082 0 0 1-1.951 1.32c-1.551 0-2.589-1.61-2.034-3.059Z"
        />
      )}

      <path
        fill="#000"
        d="m76.453 67 23.15-14.147a1 1 0 0 0 0-1.706L76.454 37v10.223H57v9.41h19.453V67Z"
        style={{
          transform: `rotate(${angle}deg)`,
          transformOrigin: "57px 52px",
        }}
      />

      <g filter="url(#a)">
        <circle cx="57" cy="52" r="31" fill="#fff" />
        <circle cx="57" cy="52" r="30.5" stroke="#e4e3ea" />
      </g>

      <defs>
        <filter
          id="a"
          width="72"
          height="72"
          x="21"
          y="21"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.92549 0 0 0 0 0.92549 0 0 0 0 0.980392 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_721_29286" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_721_29286" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

function RiskScoreImageSmall({ status, ...props }: RiskScoreImageProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" {...props}>
      <path
        fill="#cdcdec"
        d="M5.264 9C9.662 3.513 16.42 0 24 0c7.58 0 14.338 3.513 18.736 9A23.9 23.9 0 0 1 48 24c0 3.99-.974 7.753-2.697 11.063a1.776 1.776 0 0 1-1.586.939c-1.53 0-2.45-1.789-1.772-3.162A19.918 19.918 0 0 0 44 24a19.952 19.952 0 0 0-6.77-15A19.924 19.924 0 0 0 24 4a19.924 19.924 0 0 0-13.23 5A19.952 19.952 0 0 0 4 24c0 3.173.739 6.174 2.054 8.839C6.731 34.21 5.812 36 4.282 36c-.663 0-1.28-.35-1.587-.939A23.896 23.896 0 0 1 0 24c0-5.676 1.97-10.891 5.264-15Z"
      />

      {status === "risky" && (
        <path
          fill="#f00"
          d="M5.264 9C9.662 3.513 16.42 0 24 0v4a19.924 19.924 0 0 0-13.23 5A19.952 19.952 0 0 0 4 24c0 3.173.739 6.174 2.054 8.839C6.731 34.21 5.812 36 4.282 36c-.663 0-1.28-.35-1.587-.939A23.896 23.896 0 0 1 0 24c0-5.676 1.97-10.891 5.264-15Z"
        />
      )}

      {status === "unknown" && (
        <path fill="#eca336" d="M37.23 9A19.924 19.924 0 0 0 24 4V0c6.188 0 11.83 2.342 16.085 6.188L37.23 9Z" />
      )}

      {status === "good" && (
        <path
          fill="#018001"
          d="M41.945 32.84A19.918 19.918 0 0 0 44 24a19.952 19.952 0 0 0-6.77-15l2.834-2.832c.965.87 1.859 1.817 2.672 2.832A23.899 23.899 0 0 1 48 24c0 3.99-.974 7.753-2.697 11.063a1.776 1.776 0 0 1-1.586.939c-1.53 0-2.45-1.789-1.772-3.162Z"
        />
      )}
    </svg>
  );
}

export function RiskScore({ size, score, className }: RiskScoreProps) {
  const angle = getRiskScoreAngle(score);
  const status = getRiskScoreStatus(score);

  return (
    <div
      className={classNames(
        "relative size-fit",
        size === "lg" && "mx-7 -mb-9",
        size === "lg" &&
          "before:absolute before:right-full before:top-[135px] before:mr-2 before:text-sm before:font-bold before:text-status-high before:content-['-10']",
        size === "lg" &&
          "after:absolute after:left-full after:top-[135px] after:ml-2 after:text-sm after:font-bold after:text-status-low after:content-['10']",
        size === "md" && "mx-5 -mb-8",
        size === "md" &&
          "before:absolute before:right-full before:top-[67px] before:mr-0.5 before:text-xs before:font-bold before:text-status-high before:content-['-10']",
        size === "md" &&
          "after:absolute after:left-full after:top-[67px] after:ml-0.5 after:text-xs after:font-bold after:text-status-low after:content-['10']",
        size === "sm" && "-mb-3",
        className,
      )}
    >
      <span
        className={classNames(
          "absolute left-1/2 -translate-x-1/2 -translate-y-1/2 font-bold text-theme-text-body",
          size === "lg" && "top-[105px] flex flex-col items-center gap-2 text-center text-3xl text-secondary",
          size === "md" && "top-[52px] text-xl",
          size === "sm" && "top-[22px] text-base",
        )}
      >
        {score}
      </span>

      {size === "lg" && <RiskScoreImageLarge angle={angle} />}
      {size === "md" && <RiskScoreImageMedium status={status} angle={angle} />}
      {size === "sm" && <RiskScoreImageSmall status={status} />}
    </div>
  );
}
