import { useCallback, useEffect, useState } from "react";

import { BusinessScoresV3Response } from "../Services/Types";

const STORAGE_KEY = "processingUrls";

type TCacheState = {
  [key: string]: {
    timestamp: number;
    data: BusinessScoresV3Response;
  };
};

const useRunUrlProcessingCache = () => {
  const [processingUrls, setProcessingUrls] = useState<TCacheState>(
    JSON.parse(localStorage.getItem(STORAGE_KEY) || "{}"),
  );

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(processingUrls));
  }, [processingUrls]);

  const setUrlCache = useCallback((url: string, data: BusinessScoresV3Response) => {
    setProcessingUrls((prev: TCacheState) => ({
      ...prev,
      [url]: {
        timestamp: Date.now(),
        data,
      },
    }));
  }, []);

  const getUrlCache = useCallback(
    (url: string) => {
      const cache = processingUrls[url];

      if (!cache) {
        return null;
      }

      const timestamp = cache.timestamp;

      // 5 minutes
      if (Date.now() - timestamp > 300000) {
        setProcessingUrls((prev: TCacheState) => {
          const newCache = { ...prev };
          delete newCache[url];
          return newCache;
        });
        return null;
      }

      return cache.data;
    },
    [processingUrls],
  );

  return {
    setUrlCache,
    getUrlCache,
  };
};

export default useRunUrlProcessingCache;
