import { useQuery } from "@tanstack/react-query";
import { JsonEditor } from "json-edit-react";
import { FC } from "react";
import { useSearchParams } from "react-router-dom";

import LoadingScreen from "../../components/loading-screen";
import { toast } from "../../components/ui/use-toast";
import { readBusinessScores } from "../../Services/Services";

const Dashboard: FC = () => {
  const [searchParams] = useSearchParams();

  const url = searchParams.get("url");

  const {
    data: dataBusinessScores,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["readBusinessScores", searchParams.get("url"), url],
    queryFn: async () => {
      const response = await readBusinessScores({
        url,
        api_version: "2",
      });

      if (!response || !Object.keys(response).length) {
        toast({
          variant: "destructive",
          title: "Client URL is not found",
        });
      }

      return response;
    },
    enabled: !!searchParams.get("url"),
    gcTime: 0,
  });

  if (!url) {
    return <div>Missing an url!</div>;
  }

  if (isLoading || isFetching) {
    return <LoadingScreen />;
  }

  return (
    <JsonEditor
      maxWidth="100%"
      theme={[
        "default",
        {
          container: {
            backgroundColor: "#ececfa",
            borderRadius: 0,
          },
        },
      ]}
      rootFontSize={12}
      collapseAnimationTime={0}
      data={dataBusinessScores || {}}
    />
  );
};

export default Dashboard;
