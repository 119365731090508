import useLayoutVersion from "../../hooks/useLayoutVersion";
import { ClientPage as V1 } from "./v1";
import { ClientPage as V2 } from "./v2";

const ClientFactory = () => {
  const v = useLayoutVersion();

  if (v.version === 2) {
    return <V2 />;
  }

  return <V1 />;
};

export default ClientFactory;
